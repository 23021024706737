import { createTheme }  from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    
    primary: {
      light: '#79bc68',
             main: '#27a807',
             dark: '#145305',
           },
    grey: { 900: '#145305', 500: '#0000ff' },
  },
});

export default theme